import {ApiTags, baseApi} from "../../../app/api/baseApi";
import {CmQueryResult} from "../../../app/types";
import {
    CreationDetails,
    CreationForm,
    CreationListItem,
    CreationPayload,
    CreationsFilteringMeta, PossibleEvent,
    ResponsiblePerson
} from "../types";
import {Draft, DraftDetails, DraftFilteringMeta, Tag} from "../../drafts/types";
import UseApiV1 from "../../../hooks/useApiV1";


export const creationsApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getCreations: builder.query<CmQueryResult<CreationListItem[]>, string>({
            query: (query) => {
                return {
                    reducerPath: 'creations',
                    url: `/api/v1/creations?${query}`,
                    method: 'GET',
                }
            },
            providesTags: (_) => [ApiTags.CreationsList],
            keepUnusedDataFor: 5
        }),
        getCreationsFiltersMeta: builder.query<CmQueryResult<CreationsFilteringMeta>, void>({
            query: () => {
                return {
                    reducerPath: 'creationsFiltersMeta',
                    url: `/api/v1/creations/filters_meta`,
                    method: 'GET',
                }
            },
            keepUnusedDataFor: 120
        }),
        getResponsiblePersons: builder.query<ResponsiblePerson[], void>({
            query: () => {
                return {
                    reducerPath: 'responsiblePersons',
                    url: `/api/v1/users?page=1&items=20`,
                    method: 'GET',
                }
            },
            transformResponse: (response: CmQueryResult<ResponsiblePerson[]>) => {
                return response.data
            },
            keepUnusedDataFor: 120
        }),
        createCreation: builder.mutation<any, CreationPayload>({
            query: (creationPayload: CreationPayload): any => {
                return {
                    url: '/api/v1/creations',
                    method: 'POST',
                    body: {...creationPayload}
                }
            },
        }),
        getCreationDetails: builder.query<CreationDetails, string>({
            query: (creationId: string) => {
                return {
                    reducerPath: 'creationDetails',
                    url: UseApiV1(`creations/${creationId}`),
                    method: 'GET',
                }
            },
            transformResponse: (response: CmQueryResult<CreationDetails>) => {
                return response.data
            },
            providesTags: (_) => [ApiTags.CreationDetails],
            keepUnusedDataFor: 120
        }),
        partialUpdateCreation: builder.mutation<any, Pick<CreationForm, any>>({
            query: (draftFormUpdatePayload: Partial<CreationForm>): any => {
                let {id, name, creationPropertiesAttributes, ...rest} = draftFormUpdatePayload;

                return {
                    url: `/api/v1/creations/${id}`,
                    method: 'PATCH',
                    body: {...rest}
                }
            },
            invalidatesTags: (_) => [ApiTags.CreationsList, ApiTags.CreationDetails]
        }),
        updateCreation: builder.mutation<any, Pick<CreationForm, any>>({
            query: (draftFormUpdatePayload: CreationForm): any => {
                let {id, ...rest} = draftFormUpdatePayload;
                return {
                    url: `/api/v1/creations/${id}`,
                    method: 'PUT',
                    body: {...rest}
                }
            },
            invalidatesTags: (_) => [ApiTags.CreationsList, ApiTags.CreationDetails, ApiTags.EmissionsList]
        }),
        manageState: builder.mutation<any, { id: CreationForm['id'], possibleEvent: PossibleEvent }>({
            query: (updateStatePayload: { id: CreationForm['id'], possibleEvent: PossibleEvent }): any => {
                let {id, possibleEvent} = updateStatePayload;
                return {
                    url: `/api/v1/creations/${id}/manage_state`,
                    method: 'PATCH',
                    body: {event: possibleEvent}
                }
            },
            invalidatesTags: (_) => [ApiTags.CreationsList, ApiTags.CreationDetails, ApiTags.EmissionsList]
        }),
        deleteCreation: builder.mutation<any, any>({
            query: (creationId: number): any => {
                return {
                    url: `/api/v1/creations/${creationId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (_) => [ApiTags.CreationsList],
        }),
    })
})

export const {
    useGetCreationsFiltersMetaQuery,
    useGetCreationsQuery,
    useGetCreationDetailsQuery,
    useLazyGetCreationDetailsQuery,
    useCreateCreationMutation,
    usePartialUpdateCreationMutation,
    useUpdateCreationMutation,
    useGetResponsiblePersonsQuery,
    useDeleteCreationMutation,
    useManageStateMutation,
} = creationsApiSlice