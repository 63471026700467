import {Ability, AbilityBuilder, defineAbility, MongoAbility} from '@casl/ability';
import {PermissionAction, ResourceName, Role, UserAuthData} from "../features/auth/types";
import {PERMISSION_ACTIONS, RESOURCES} from "../config/permissions";

export interface PermissionObject {
    [key: string]: any
}

export type AppAbility = MongoAbility<[PermissionAction, ResourceName]>;
export default defineAbility<AppAbility>((can, cannot) => {
    const user = JSON.parse(localStorage.getItem('user') || "{}") as UserAuthData;
    if (user && user.profile) {
        setAbilities(user.profile.roles, can)
    }
});

export function updateAbility(ability: MongoAbility, roles: Role[]) {
    const {can, rules} = new AbilityBuilder(Ability);
    setAbilities(roles, can);
    ability.update(rules);
}



function setAbilities(roles: Role[], can: any) {
    if (roles.some((role) => role.defaultPolicy === 'allow')) {
        RESOURCES.forEach((resource: ResourceName) => {
            PERMISSION_ACTIONS.forEach((action: PermissionAction) => {
                can(action, resource)
            })
        })
    } else {
        const permissions: PermissionObject = {}
        roles.forEach(role => {
            role.operations.forEach(operation => {
                permissions[operation.resourceName] = {
                    ...permissions[operation.resourceName],
                    [operation.action]: ''
                }
            })
        })
        for (const resource in permissions) {
            for (const action in permissions[resource]) {
                can(action, resource)
            }
        }
    }
}
