import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Box} from "@mui/material";
import CmCreationStatusIconBox from "../../../components/CmCreationStatusIconBox/CmCreationStatusIconBox";
import React from "react";
import {CreationsTableProps} from "./types";
import './CreationsTable.scss'
import {CreationState} from "../../creations/types";

const CreationsTable: React.FC<CreationsTableProps> = ({creationsQueryResult, buttonGroup}) => {

    const getCreationColorState = (creationState: CreationState, startAt: string, endAt:string) => {

        if (creationState === 'draft') {
            return 'draft'
        }

        if  (creationState === 'accepted' && new Date(startAt) < new Date() && new Date(endAt) >= new Date()) {
            return 'published'
        }

        if (creationState === 'accepted' && new Date(startAt) > new Date()) {
            return 'planned'
        }

        if (creationState === 'accepted' && new Date() > new Date(endAt)) {
            return 'published'
        }

        return null
    }


    return <>
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nazwa kreacji</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {creationsQueryResult.data.map((row) => (
                        <TableRow
                            className={`CreationStatus--${getCreationColorState(row.state, row.startAt, row.endAt)}`}
                            key={row.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">
                                <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                    <CmCreationStatusIconBox state={row.state}></CmCreationStatusIconBox>
                                </Box>
                            </TableCell>
                            <TableCell align="right">
                                {buttonGroup(row.id)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    </>
};

export default CreationsTable;