import React from 'react';
import {Menu, MenuItem, Sidebar, SubMenu, useProSidebar} from "react-pro-sidebar";
import {Link, useLocation, useNavigate} from "react-router-dom";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import {useDispatch} from "react-redux";
import {logOut} from "../../features/auth/authSlice";
import {useSnackbar} from 'notistack';
import './CmSidebar.scss'
import {
    AddCard,
    AutoAwesomeMosaic,
    Pageview,
    PersonalVideo,
    Schema,
    Subscriptions,
    ViewInAr
} from "@mui/icons-material";
import ability from  "../../utils/ability";
import {ApiTags, baseApi} from "../../app/api/baseApi";

const CmSidebar = () => {
    const {collapseSidebar} = useProSidebar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();
    const logout = () => {
        dispatch(logOut())
        navigate('/login')
        enqueueSnackbar('Pomyślnie wylogowano użytkownika', {variant: 'success'});
    }

    return (<><Sidebar className="app" width={'290px'}>
        <Menu>
            <MenuItem
                onClick={() => {
                    collapseSidebar();
                }}
                className="menu1"
                icon={<MenuRoundedIcon/>}
            >
            </MenuItem>
            {(ability.can('index', 'Creation') || ability.can('create', 'Creation')) && (<SubMenu label="Zarządzanie kreacjami" icon={<Subscriptions/>}
                      active={location.pathname === '/creations' || location.pathname.includes('/new-creation')}>
                {ability.can('index', 'Creation') &&
                    (<MenuItem active={location.pathname === '/creations'} component={<Link to="/creations"/>}
                               icon={<AddCard/>}>Lista
                        kreacji</MenuItem>)
                }
                {ability.can('create', 'Creation') && (
                    <MenuItem active={location.pathname.includes('new-creation')} icon={<Schema/>} onClick={() => {
                        dispatch(baseApi.util.invalidateTags([ApiTags.DraftDetails]))
                        navigate('/new-creation')
                    }}>
                        Stwórz kreacje</MenuItem>)}
            </SubMenu>)}

            {(ability.can('index', 'Draft') || ability.can('create', 'Draft')) && (<SubMenu label="Zarządzanie schematami" icon={<Subscriptions/>}
                      active={location.pathname === '/' || location.pathname === '/new-draft'}>
                {ability.can('index', 'Draft') &&
                    (<MenuItem active={location.pathname === '/'} component={<Link to=""/>} icon={<AddCard/>}>Lista
                        schematów</MenuItem>)
                }
                {ability.can('create', 'Draft') && (
                    <MenuItem active={location.pathname === '/new-draft'} icon={<Schema/>}
                              component={<Link to="/new-draft" className="link"/>}>Stwórz schemat</MenuItem>)}
            </SubMenu>)}
            <SubMenu active={location.pathname === '/emissions'} label="Zarządzanie emisją" icon={<AutoAwesomeMosaic/>}>
                <MenuItem active={location.pathname === '/emissions'} component={<Link to="/emissions"/>} icon={<Pageview/>}>Podgląd emisji</MenuItem>
                <MenuItem icon={<ViewInAr/>}>Sprawdzenie ramówki</MenuItem>
                <MenuItem active={location.pathname === '/emissions/new-schedule'} component={<Link to="/emissions/new-schedule"/>} icon={<PersonalVideo/>}>Zaplanuj ramówkę VOOH</MenuItem>
            </SubMenu>
            {ability.can('index', 'MediabankResource') && (<SubMenu active={location.pathname === '/media-list' || location.pathname.includes('/media-list')}
                      label="Mediabank" icon={<BarChartRoundedIcon/>}>
                <MenuItem active={location.pathname === '/media-list'} icon={<TimelineRoundedIcon/>}
                          component={<Link to="/media-list" className="link"/>}>Lista plików</MenuItem>
            </SubMenu>)}
            <SubMenu label="Raportowanie" icon={<BarChartRoundedIcon/>}>
                <MenuItem icon={<TimelineRoundedIcon/>}>lista</MenuItem>
            </SubMenu>
            <SubMenu label="Zarządzanie dostępem" icon={<BarChartRoundedIcon/>}>
                <MenuItem icon={<TimelineRoundedIcon/>}>Lista</MenuItem>
            </SubMenu>

            <MenuItem onClick={logout} icon={<LogoutRoundedIcon/>}> Logout </MenuItem>
        </Menu>
    </Sidebar></>)
}

export default CmSidebar;