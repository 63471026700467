export interface MediabankResource {
    id: number;
    parentId: number | null;
    name: string;
    tagList: string[];
    categoryId: number;
    resourceType: ResourceType;
    resourceFileUrl: string | null;
    updatedAt: string;
    thumbnailUrl: string | null;
    byteSizeHumanized: string | null;
    byteSize: number;
    length: number;
    editableLength: boolean;
    metadata?: {
        height: number,
        width: number,
    }
}

export interface MediabankResourceTableRow extends MediabankResource {
    actions: string;
}


export enum ResourceType {
    Dir = 'dir',
    File = 'file'
}

export interface MediabankResourcesFilters {
    nameCont: string;
    tagsNameIn: string[],
    updatedAtEq: string,
    parentIdEq: string | null,
}

export interface FilterItemChip {
    name: string,
    value: string | number
    isArray?: boolean
}

export interface ResourcePayload {
    name: string;
    resourceType: ResourceType;
    parentId?: string;
    tagList: string[]
}

export interface MediabankFile {
    uuid: string;
    tagList: string[];
    categoryId: number | null;
    file: File;
}

export interface ChangeResourceLocationPayload {
    id: number;
    parentId: number | null;
}

export type MediabankResourceWithPropID = MediabankResource & {propId: number};

export interface ChangeMediabankResourceNamePayload {
    resourceId: number;
    name: string;
}