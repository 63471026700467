import {CmQueryResult} from "../../../app/types";
import UseApiV1 from "../../../hooks/useApiV1";
import {baseApi} from "../../../app/api/baseApi";
import {DraftDetails} from "../../drafts/types";
import {GetResourcesMetaDataResponse, ResourceMetaData} from "../types";


export const voohApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getResourcesMetaData: builder.query<ResourceMetaData[], string>({
            query: (resourceIdsQuery: string) => {
                return {
                    reducerPath: 'creations',
                    url: UseApiV1(`vooh_playlists/build_vooh_items?${resourceIdsQuery}`),
                    method: 'GET',
                }
            },
            transformResponse: (response: CmQueryResult<GetResourcesMetaDataResponse>) => {
                return response.data.voohItems.map((el) => {
                    if (el.duration) {
                        return {
                            ...el,
                            editableDuration: false
                        }
                    }
                    return {
                        ...el,
                        editableDuration: true
                    }
                })
            },
            keepUnusedDataFor: 5
        }),
    })
})

export const {
    useLazyGetResourcesMetaDataQuery
} = voohApiSlice