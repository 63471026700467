import * as React from 'react';

export interface DraftListProps {
    showHeader?: boolean
    showCrudActions?: boolean,
    selectable?: boolean,
    greyOutNotCompleted?: boolean,
    onRowClick?: (clickedRow: Draft) => void,
    activeDraftId?: number | null
}


export interface Draft {
    id: number;
    name: string;
    mediaFormat: string;
    tagList: string[];
    draftProperties: DraftProperty[],
    media: Media[],
    locations: Location[],
    createdAt: string,
    updatedAt: string,
    completed: boolean,
}

export interface Location {
    id: number;
    name?: string;
    type?: string;
}

export interface DraftProperty {
    name: string;
    propType: string;
    id: number;
    createdAt: string;
    updatedAt: string;
}

export interface Media {
    id: number;
    asId?: number;
    mediaFormat?: string;
    address?: string
}

export interface DraftsPage {
    data: Draft[];
    meta: {
        page: number;
        count: number;
        items: number;
    }
}

export interface DraftsFilters {
    page: number;
    nameCont: string;
    mediaFormatIn: string[];
    agglomerationsNameIn: string[];
    citiesNameIn: string[];
    mediaAddressIn: string[];
    mediaAsIdIn: number[];
    buildingsNameIn: string[];
    tagsNameIn: string[];
}

export interface DraftForm {
    id: null | number,
    name: string;
    mediaFormat: string | undefined;
    tagList: string[];
    draftPropertiesAttributes: PropertyAttribute[];
    localisablesMediatablesAttributes: MediatableAttribute[];
}

export type DraftDetails = Pick<DraftForm, "id" | "name" | "mediaFormat" | "tagList"> & {
    draftProperties: PropertyAttribute[]
    draftFileUrl: string
    media: Media[]
};

export enum MediaShape {
    horizontal = 'horizontal',
    vertical = 'vertical',
    square = 'square',
}

export enum MediaFormat {
    dgCl = 'DG_CL',
    dgIn = 'DG_IN'
}

export interface PropertyAttribute {
    id?: number;
    _destroy?: boolean;
    name: string;
    key:string;
    propType: string;
    required: boolean;
    value?: string | number | boolean | null;
}

export interface MediatableAttribute {
    localisableId: number,
    localisableType: string,
    _destroy?: boolean,
}

export interface FilterItemChip {
    name: string,
    value: string | number
    isArray?: boolean
}

export interface DraftFilteringMeta {
    filtering: {
        fields: {
            agglomerationsNameIn: {
                allowedValues: string[]
            }
            buildingsNameIn: {
                allowedValues: string[]
            }
            citiesNameIn: {
                allowedValues: string[]
            }
            mediaAddressIn: {
                allowedValues: string[]
            }
            mediaAsIdIn: {
                allowedValues: string[]
            }
            mediaFormatIn: {
                allowedValues: string[]
            }
            nameCont: {
                allowedValues: string
            }
            tagsNameIn: {
                allowedValues: string[]
            }
        }
    }
    order: {
        by: string[]
        dir: string[]
    }
}

export enum PropType {
    Integer = 'integer',
    String = 'string',
}

export interface CustomProp {
    key: string
    name: string,
    propType: string,
    required: boolean,
}

export interface FileUpload {
    draftId: number,
    file: any
}

export interface Tag {
    name: string,
}

export interface MediaNode {
    id: number,
    type: string,
    name: string,
    asId?: string,
    address?: string,
    showOnTree?: boolean,
    children?: MediaNode[]
}