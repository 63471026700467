import React from 'react';
import {StatusIconBoxProps} from "./types";
import ModeIcon from "@mui/icons-material/Mode";
import {Box, Typography}     from "@mui/material";
import {useTranslation} from "react-i18next";
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

const CmCreationStatusIconBox: React.FC<StatusIconBoxProps> = ({state}) => {
    const {t} = useTranslation();
    const sx = { display: 'flex', alignItems: 'center'}
    
    switch (state) {
        case 'accepted':
            return <Box sx={sx}>
                <div className={'IconBox IconBox--green'}><CheckIcon></CheckIcon></div>
                <Typography sx={{marginLeft: '10px', maxWidth: '120px'}}>{t('creations.status.accepted')}</Typography>
            </Box>;
        case 'rejected':
            return <Box sx={sx}>
                <div className={'IconBox IconBox--red'}><DoDisturbOnIcon></DoDisturbOnIcon></div>
                <Typography sx={{marginLeft: '10px', maxWidth: '40px'}}>{t('creations.status.rejected')}</Typography>
            </Box>;
        case 'completed':
            return <Box sx={sx}>
                <div className={'IconBox IconBox--green-dark'}><CheckCircleIcon></CheckCircleIcon></div>
                <Typography sx={{marginLeft: '10px', maxWidth: '80px'}}>{t('creations.status.completed')}</Typography>
            </Box>;
        case 'pending_review':
            return <Box sx={sx}>
                <div className={'IconBox IconBox--blue'}><PendingActionsIcon></PendingActionsIcon></div>
                <Typography sx={{marginLeft: '10px', maxWidth: '100px'}}>{t('creations.status.pending_review')}</Typography>
            </Box>;
        default:
            return (<Box sx={sx}>
                <div className={'IconBox IconBox--purple'}><ModeIcon></ModeIcon></div>
                <Typography sx={{marginLeft: '10px', maxWidth: '40px'}}>{t('creations.status.draft')}</Typography>
            </Box>);
    }
}

export default CmCreationStatusIconBox;