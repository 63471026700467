import React, {useEffect} from 'react';
import {useSearchParams} from "react-router-dom";
import JSZip from "jszip";
import {mediaFormatDimensions} from "../../../config/mediaFormatDimensions";
import './CreationPreview.scss'
import {Box, Button} from "@mui/material";


const IFRAME_ID = 'PLAYER_IFRAME';

const CreationPreview = () => {

    const [searchParams] = useSearchParams();

    const mediaFormat = searchParams.get('mediaFormat');

    const fetchZipFileUrl = async () => {
        try {
            const response = await fetch(searchParams.get('fileUrl') || '');
            if (!response.ok) {
                throw new Error('Failed to download the file');
            }
            return await response.blob();
        } catch (error) {
            console.error('Error while downloading the file:', error);
        }
    }

    const writeIframeDocument = (files: JSZip.JSZipObject) => {
        const currentIframe = document.getElementById(IFRAME_ID) as HTMLObjectElement;
        const htmlFileName = Object.keys(files).find(fileName => fileName.endsWith('.html'));
        const iframeDoc = currentIframe.contentDocument || currentIframe?.contentWindow?.document;

        if (currentIframe && iframeDoc) {
            iframeDoc.open();
            iframeDoc.write(replaceMediaUrlsInHtml(files[htmlFileName as keyof typeof files], files))
            iframeDoc.close();
        }
    }

    function replaceMediaUrlsInHtml(htmlContent: any, files: any) {
        return htmlContent.replace(/(src|href)=['"]?([^'"]+)['"]?/g, (match: any, attr: any, mediaPath: any) => {
            const mediaFileName = mediaPath.split('/').pop();
            if (files[mediaFileName]) {
                if (mediaFileName.endsWith('.svg')) {
                    return `${attr}="data:image/svg+xml;base64,${btoa(files[mediaFileName])}"`;
                } else if (mediaFileName.endsWith('.css')) {
                    const cssTextWithUrlsReplaced = replaceMediaUrlsInCss(files[mediaFileName], files);
                    return `${attr}="data:text/css;base64,${btoa(cssTextWithUrlsReplaced)}"`;
                } else {
                    const mediaBlob = files[mediaFileName];
                    const mediaUrl = URL.createObjectURL(mediaBlob);
                    return `${attr}="${mediaUrl}"`;
                }
            }
            return match;
        });
    }

    function replaceMediaUrlsInCss(cssText: string, files: any) {
        return cssText.replace(/url\(['"]?([^'"]+)['"]?\)/g, (match, mediaPath) => {
            const mediaFileName = mediaPath.split('/').pop();
            if (files[mediaFileName]) {
                const mediaBlob = files[mediaFileName];
                const mediaUrl = URL.createObjectURL(mediaBlob);
                return `url(${mediaUrl})`;
            }
            return match;
        });
    }

    let maxWidth  = mediaFormatDimensions[mediaFormat as keyof typeof mediaFormatDimensions][0],
        outer = document.getElementById(IFRAME_ID) as HTMLObjectElement,
        maxHeight = mediaFormatDimensions[mediaFormat as keyof typeof mediaFormatDimensions][1];

    function resize() {
        const iframe = document.getElementById(IFRAME_ID);
        const container = document.getElementById('wrapper-outside');

        const scale = (window.innerHeight / (maxHeight + 500)) ;
        (iframe as HTMLObjectElement).style.transform = `scale(${scale})`;
    }

    useEffect(() => {
        fetchZipFileUrl().then(async (blob) => {
            if (!blob) {
                return;
            }
            const files: any = {}
            const zip = await JSZip.loadAsync(blob);
            for (const fileName of Object.keys(zip.files)) {
                const file = zip.files[fileName];
                if (!file.dir) {
                    const content = fileName.endsWith('.svg') || fileName.endsWith('.css') || fileName.endsWith('.html')
                        ? await file.async("text")
                        : await file.async("blob");
                    files[fileName] = content;
                }
            }

            writeIframeDocument(files);


        })
        window.addEventListener("resize", resize);
    })


    return <>
        <Box sx={{display: 'flex', justifyContent: 'center', paddingTop: '10px'}}><Button color={'primary'}
                                                                                          variant={"contained"}
                                                                                          onClick={() => {
                                                                                              window.location.reload()
                                                                                          }}>Refresh</Button></Box>
        <div className={'CreationPreview__container'} id={'wrapper-outside'} style={{left: window.innerWidth }}>
                <iframe id={IFRAME_ID} height={ maxHeight} width={maxWidth} style={{margin: '80px', transform: 'scale(' + window.innerHeight / (maxHeight + 500) + ')' }}>
                </iframe>
        </div>
    </>
}

export default CreationPreview;