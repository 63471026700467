import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {VoohForm} from "../types";

export interface VoohInitialState {
    voohForm: VoohForm
}

export const initialVoohFormState: VoohInitialState = {
    voohForm: {
        name: '',
        mediaFormat: 'Vooh',
        tagList: [],
        startAt: '',
        endAt: '',
        localisablesMediatablesAttributes: [],
        voohItemsAttributes: [],
        ownershipsAttributes: []
    }
}

export const voohSlice = createSlice({
    name: "drafts",
    initialState: initialVoohFormState,
    reducers: {
        setVoohForm: (state, action: PayloadAction<VoohForm>) => {
            state.voohForm = action.payload
        }
    }
})

export const {setVoohForm} = voohSlice.actions;

export const selectVoohForm = (state: RootState) => state.vooh.voohForm;

