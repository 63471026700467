import {MediabankResource} from "../mediabank/types";
import {MediatableAttribute} from "../drafts/types";
import {OwnershipAttribute} from "../creations/types";

export interface GetResourcesMetaDataResponse {
    id: string;
    name: string;
    voohItems: ResourceMetaData[]
}


export type ResourceMetaData = Pick<MediabankResource, 'id' | 'byteSize' | 'byteSizeHumanized'> & {categoryColor: string, categoryName: string, categoryId:number, duration: number, itemType: string, mediabankResourceId: number, position: number, editableDuration: boolean, voohItemType: VoohItemType};

export interface VoohCategory {
    id: number
    name: string;
    color: string;
}

export enum VoohItemType {
    Advertisement,
    CustomMedia
}

export interface VoohForm {
    name: string;
    mediaFormat: string | undefined;
    tagList: string[];
    startAt: string;
    endAt: string;
    voohItemsAttributes: VoohItem[];
    localisablesMediatablesAttributes: MediatableAttribute[];
    ownershipsAttributes: OwnershipAttribute[];
}

export interface VoohItem {
    mediabankResourceId: number;
    position: number;
    duration: number;
    itemType: string;
}

export type ScheduleRow = Partial<ResourceMetaData> & {name?: string, uuid: string, voohItemType: VoohItemType};