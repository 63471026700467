import {configureStore} from "@reduxjs/toolkit";
import {baseApi} from "./app/api/baseApi";
import {creationsSlice} from "./features/creations/store/creationsSlice";
import {authSlice} from "./features/auth/authSlice";
import {draftsSlice} from "./features/drafts/store/draftsSlice";
import {mediabankResourcesSlice} from "./features/mediabank/store/mediabankResourcesSlice";
import {emissionsSlice} from "./features/emissions/store/emissionsSlice";
import {voohSlice} from "./features/vooh/store/voohSlice";

export const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,
        auth: authSlice.reducer,
        creations: creationsSlice.reducer,
        drafts: draftsSlice.reducer,
        emissions: emissionsSlice.reducer,
        mediabank: mediabankResourcesSlice.reducer,
        vooh: voohSlice.reducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(baseApi.middleware),
    devTools: true
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;