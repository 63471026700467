import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {EmissionsFilters} from "../types";

export interface EmissionsInitialState {
    filters: EmissionsFilters;
}

export const initialEmissionsState: EmissionsInitialState = {
    filters: {
        page: 1,
        nameCont: '',
        emissionStatusEq: '',
        startAtLteq: '',
        endAtQteq: '',
    },
}

export const emissionsSlice = createSlice({
    name: "emissions",
    initialState: initialEmissionsState,
    reducers: {
        setFilters: (state, action: PayloadAction<EmissionsFilters>) => {
            state.filters = action.payload
        },
    }
})

export const {setFilters} = emissionsSlice.actions;

export const selectCurrentFiltersState = (state: RootState) => state.emissions.filters;

