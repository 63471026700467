import React from 'react';
import './App.scss';
import {Route, Routes, useLocation} from "react-router-dom";
import DraftsList from "../features/drafts/list/DraftsList";
import Login from "../features/auth/Login";
import {Provider} from "react-redux";
import {store} from "../store";
import Layout from "../components/Layout";
import RequireAuth from "../features/auth/RequireAuth";
import {AuthInitialState} from "../features/auth/authSlice";
import {useProSidebar} from 'react-pro-sidebar';
import CmSidebar from "../components/CmSidebar/CmSidebar";
import CmTopBar from "../components/CmTopBar/CmTopBar";
import DraftForm from "../features/drafts/form/DraftForm";
import CreationForm from '../features/creations/form/CreationForm';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pl';
import {LocalizationProvider} from '@mui/x-date-pickers';
import CreationsList from "../features/creations/list/creationsList";
import MediaList from "../features/mediabank/list/MediaList";
import EmissionsList from "../features/emissions/list/emissionsList";
import CreationPreview from "../features/creations/preview/CreationPreview";
import VoohForm from "../features/vooh/form/VoohForm";

function App() {
    const user = JSON.parse(localStorage.getItem('user') || "{}") as AuthInitialState;
    const location = useLocation();

    return (
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                <Routes>
                    <Route path='/creations/:creationId/preview' element={<CreationPreview/>}></Route>
                </Routes>
                {!location.pathname.includes('preview') && (<CmTopBar></CmTopBar>)}
                {!location.pathname.includes('preview') && (<div className={(location.pathname !== '/login') ? 'App_container--with-sidebar' : ''}>
                    {(user && location.pathname !== '/login' && !location.pathname.includes('preview')) && (
                        <CmSidebar></CmSidebar>)}

                    <section className={'App__content-container'}>
                        <Routes>
                            <Route path='/' element={<Layout/>}>
                                <Route path='/login' element={<Login/>}></Route>
                                <Route element={<RequireAuth/>}>
                                    <Route path='/' element={<DraftsList/>}></Route>
                                    <Route path='/creations' element={<CreationsList/>}></Route>
                                    <Route path='/new-draft' element={<DraftForm/>}></Route>
                                    <Route path='/new-creation' element={<CreationForm/>}></Route>
                                    <Route path='/creations/:creationId' element={<CreationForm/>}></Route>
                                    <Route path='/drafts/:id' element={<DraftForm/>}></Route>
                                    <Route path='/drafts/:draftId/new-creation' element={<CreationForm/>}></Route>
                                    <Route path='/media-list' element={<MediaList/>}></Route>
                                    <Route path='/emissions' element={<EmissionsList/>}></Route>
                                    <Route path='/emissions/new-schedule' element={<VoohForm/>}></Route>
                                </Route>
                            </Route>

                        </Routes>
                    </section>
                </div>)}
            </LocalizationProvider>

        </Provider>
    );
}

export default App;
