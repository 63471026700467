import React from 'react';
import {EmissionsStatusIconBoxProps} from "./types";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const CmEmissionsStatus: React.FC<EmissionsStatusIconBoxProps> = ({emissionStatus}) => {
    const {t} = useTranslation();
    const sx = {display: 'flex', alignItems: 'center'}

    if (emissionStatus === 'planned') {
        return <Box sx={sx}>
            <div className={'IconBox IconBox--yellow'}><PlayCircleOutlineIcon></PlayCircleOutlineIcon></div>
            <Typography sx={{marginLeft: '10px', maxWidth: '120px'}}>Zaplanowana</Typography>
        </Box>;
    }

    if (emissionStatus === 'active') {
        return <Box sx={sx}>
            <div className={'IconBox IconBox--green'}><PlayCircleOutlineIcon></PlayCircleOutlineIcon></div>
            <Typography sx={{marginLeft: '10px', maxWidth: '120px'}}>Aktywna</Typography>
        </Box>;
    }

    if (emissionStatus === 'paused') {
        return <Box sx={sx}>
            <div className={'IconBox IconBox--red'}><PlayCircleOutlineIcon></PlayCircleOutlineIcon></div>
            <Typography sx={{marginLeft: '10px', maxWidth: '120px'}}>Zatrzymana</Typography>
        </Box>;
    }

    return <Box sx={sx}>
        <div className={'IconBox IconBox--purple'}><PlayCircleOutlineIcon></PlayCircleOutlineIcon></div>
        <Typography sx={{marginLeft: '10px', maxWidth: '120px'}}>Zakonczona</Typography>
    </Box>;
}

export default CmEmissionsStatus;