import {ApiTags, baseApi} from "../../../app/api/baseApi";
import {CmQueryResult} from "../../../app/types";
import {EmissionListItem, EmissionsFilteringMeta} from "../types";
import {DraftFilteringMeta} from "../../drafts/types";


export const emissionsApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getEmissionsFiltersMeta: builder.query<CmQueryResult<EmissionsFilteringMeta>, void>({
            query: () => {
                return {
                    reducerPath: 'emissionsFiltersMeta',
                    url: `/api/v1/emissions/filters_meta`,
                    method: 'GET',
                }
            },
            keepUnusedDataFor: 120
        }),
        getEmissions: builder.query<CmQueryResult<EmissionListItem[]>, string>({
            query: (query) => {
                return {
                    reducerPath: 'creations',
                    url: `/api/v1/emissions?${query}`,
                    method: 'GET',
                }
            },
            providesTags: (_) => [ApiTags.EmissionsList],
            keepUnusedDataFor: 5
        }),
    })
})

export const {
    useGetEmissionsFiltersMetaQuery,
    useGetEmissionsQuery
} = emissionsApiSlice